//const jQuery = global.Jquery = window.$ = window.jQuery = require("jquery");
import $ from 'jquery';
window.$ = window.jQuery = $;
// JQUERY  UI
require ('webpack-jquery-ui');
require ('webpack-jquery-ui/css');

const bootstrap = require ("bootstrap");
import "../assets/neon/css/neon-core.css";
import "../assets/neon/css/neon-theme.css";
import "../assets/neon/js/gsap/TweenMax.min"
import "bootstrap/dist/css/bootstrap.css";
import "../assets/neon/js/neon-api"
import "../assets/neon/js/neon-login"
import "../assets/neon/js/resizeable"
import "../assets/neon/js/jquery.validate"


